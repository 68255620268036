interface VimeoVideoUrl {
  url: string;
}

export function VimeoVideoPlayerComponent(props: VimeoVideoUrl) {
  return (
    <div
      style={{
        width: "65%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <iframe
        style={{ height: "100%", width: "60%" }}
        src={props.url}
        allow="autoplay; fullscreen; picture-in-picture"
        allowFullScreen={true}
        title="Topic 8 Chemical Reactions and Energy Lesson 1.mp4"
      ></iframe>
      <script src="https://player.vimeo.com/api/player.js"></script>
    </div>
  );
}
