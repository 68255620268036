import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { ApolloClient, InMemoryCache, ApolloProvider } from "@apollo/client";
import { createUploadLink } from "apollo-upload-client";
import { BrowserRouter } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";

// const link = createUploadLink({
//   uri: "http://localhost:3000/api/graphql",
//   credentials: "include",
// });
// export const client = new ApolloClient({
//   uri: "http://localhost:3000/api/graphql",
//   cache: new InMemoryCache(),
//   link: link,
// });

const link = createUploadLink({
  uri: process.env.REACT_APP_WEBSITE_NAME,
  credentials: "include",
});
export const client = new ApolloClient({
  uri: process.env.REACT_APP_WEBSITE_NAME,
  cache: new InMemoryCache(),
  link: link,
});
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <ApolloProvider client={client}>
    <BrowserRouter>
      <React.StrictMode>
        <App />
      </React.StrictMode>
    </BrowserRouter>
  </ApolloProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
