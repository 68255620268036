import { Button } from "@mui/material";

interface RelatedCourseProp {
  img: string;
  header: string;
  ref: string;
}
interface RelatedCoursesProp {
  courseProp: RelatedCourseProp;
}
const course_1: RelatedCourseProp = {
  img: "/imgs/chisato.png",
  header: "Redox Reaction",
  ref: "string",
};
const course_2: RelatedCourseProp = {
  img: "/imgs/chisato.png",
  header: "Acid and Base",
  ref: "string",
};
const course_3: RelatedCourseProp = {
  img: "/imgs/chisato.png",
  header: "Rate of Reaction",
  ref: "string",
};
export const course_1_data: RelatedCoursesProp = {
  courseProp: course_1,
};
export const course_2_data: RelatedCoursesProp = {
  courseProp: course_2,
};
export const course_3_data: RelatedCoursesProp = {
  courseProp: course_3,
};

export default function RelatedCourseComponent(props: RelatedCoursesProp) {
  return (
    <div style={{ justifyContent: "center", display: "flex" }}>
      <div>
        <img
          src={props.courseProp.img}
          style={{
            width: "300px",
            height: 300,
            borderRadius: 20,
            marginBottom: 10,
          }}
        />
        <h1 style={{ textAlign: "center" }}>{props.courseProp.header}</h1>
        <div
          style={{ justifyContent: "center", display: "flex", marginTop: 50 }}
        >
          <Button
            variant="contained"
            href="https://docs.google.com/forms/d/e/1FAIpQLSdLshb3xPMX316IzyAjFgTrUc34CmSftile44b25DgmhvKI-w/viewform"
          >
            See More
          </Button>
        </div>
      </div>
    </div>
  );
}
