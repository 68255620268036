// import "./styles.css";
// import { styled } from "@mui/material/styles";
import React, { useEffect, useState } from "react";
import { useQuery, gql, useMutation, useLazyQuery } from "@apollo/client";
import { Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import About from "./pages/About";
import Course from "./pages/Course";
import { Navbar } from "./component/NavBar";
import LoginPage from "./pages/LoginPage";
import CourseDetail from "./pages/CourseDetail";
export default function App() {
  useEffect(() => {
    document.title = "CYCHEMISTRY - Online Video Course";
  }, []);
  return (
    <div className="App">
      {/* <Navbar /> */}
      <Routes>
        {/* <Route path="/" element={<Home />} /> */}
        <Route path="/" element={<LoginPage />} />
        <Route path="/About" element={<About />} />
        <Route path="/Course" element={<Course />} />
        <Route path="/Login" element={<LoginPage />} />
        <Route path="/Course/CourseDetails/:id" element={<CourseDetail />} />
      </Routes>
    </div>
  );
}
