interface BannerContent {
  content: string;
}

export default function Banner(props: BannerContent) {
  return (
    <div
      style={{
        width: "100%",
        height: "150px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "#1b2e60",
        marginBottom: 20,
      }}
    >
      <h1
        style={{ fontFamily: "impact", fontStyle: "regular", color: "white" }}
      >
        {" "}
        {props.content}
      </h1>
    </div>
  );
}
