import { gql } from "@apollo/client";

export const LOGOUT_GQL = gql`
  mutation Logout {
    endSession
  }
`;

export const LOGIN_GQL = gql`
  mutation Login($email: String!, $password: String!) {
    authenticateUserWithPassword(email: $email, password: $password) {
      ... on UserAuthenticationWithPasswordSuccess {
        sessionToken
        item {
          id
          email
          name
          role
        }
      }
      ... on UserAuthenticationWithPasswordFailure {
        message
      }
    }
  }
`;
export const CHECK_LOGIN_GQL = gql`
  mutation CheckLogin($email: String!) {
    CheckLoginValid(email: $email) {
      status
    }
  }
`;
