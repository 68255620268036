import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import GoogleDriveFileUploader from "./GoogleAuth";

export interface VideoTableProps {
  courseTitle: string;
  videoInfos: VideoProp[];
  studentWorks: StudentWorkProp[];
  playVideo: (url: string) => void;
}

export interface VideoProp {
  form: string;
  chapter: string;
  part: string;
  url: string;
  expireDate: string;
}

export interface StudentWorkProp {
  name: string;
  topic: string;
  school: string;
  link: string;
}

export function VideoTable(props: VideoTableProps) {
  const [expandedRows, setExpandedRows] = React.useState(false);
  const handleRollUp = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <>
      <h1
        style={{ fontFamily: "impact", fontStyle: "regular", color: "Black" }}
      >
        {props.courseTitle}
      </h1>
      <TableContainer
        component={Paper}
        style={{ width: "70%", marginTop: 30, marginBottom: 30 }}
      >
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead
            sx={{
              backgroundColor: "#1b2e60",
              "&:hover": { backgroundColor: "#354d8f" },
            }}
            onClick={() => setExpandedRows(!expandedRows)}
          >
            <TableRow>
              <TableCell
                sx={{
                  color: "white",
                  fontFamily: "impact",
                  fontStyle: "regular",
                }}
              >
                Topic
              </TableCell>
              <TableCell
                sx={{
                  color: "white",
                  fontFamily: "impact",
                  fontStyle: "regular",
                }}
                align="right"
              >
                Lesson
              </TableCell>
              {/* <TableCell align="right">Expiry Date</TableCell> */}
              <TableCell
                sx={{
                  color: "white",
                  fontFamily: "impact",
                  fontStyle: "regular",
                }}
                align="right"
              >
                Click to Play
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {expandedRows &&
              props.videoInfos.map((row, videoIdx: number) => (
                <TableRow
                  key={row.chapter + videoIdx.toString()}
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                  }}
                >
                  <TableCell component="th" scope="row">
                    {row.chapter}
                  </TableCell>
                  <TableCell align="right">{row.part}</TableCell>
                  {/* <TableCell align="right">{row.expireDate}</TableCell> */}
                  {/* <TableCell align="right">{row.url}</TableCell> */}
                  <TableCell align="right">
                    <Button
                      onClick={() => {
                        handleRollUp();
                        props.playVideo(row.url);
                      }}
                    >
                      play
                    </Button>
                  </TableCell>
                </TableRow>
                // </Button>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
