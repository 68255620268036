import React, { useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Link,
  useParams,
} from "react-router-dom";
import Button from "@mui/material/Button";
import { Box, ButtonBase, Collapse, Divider, Grid, Paper } from "@mui/material";
import IconButton, { IconButtonProps } from "@mui/material/IconButton";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { createTheme, ThemeProvider, styled } from "@mui/material/styles";
import { IconBaseProps } from "react-icons";
import { Stack } from "@mui/system";
import RelatedCourseComponent, {
  course_1_data,
  course_2_data,
  course_3_data,
} from "../component/RelatedCourseComponent";
import { Padding } from "@mui/icons-material";

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  textAlign: "center",
  color: theme.palette.text.secondary,
  width: "100%",
  lineHeight: "60px",
}));

const ExpandTab = () => {
  const [expand, setExpand] = useState(false);
  const [touch, setTouch] = useState(false);
  return (
    <ButtonBase
      style={{
        width: "85%",
        height: "100%",
        margin: 10,
      }}
      onClick={() => {
        setExpand(!expand);
      }}
      onMouseEnter={() => {
        setTouch(true);
      }}
      onMouseLeave={() => {
        setTouch(false);
      }}
    >
      <Item
        style={{ backgroundColor: touch && !expand ? "lightgray" : "white" }}
      >
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <h1 style={{ marginLeft: 30 }}>dnlm</h1>
          <ExpandMoreIcon style={{ alignSelf: "center", marginRight: 30 }} />
        </div>
        <Collapse in={expand}>
          <Divider />
          <p>Header</p>
          <p>Header</p>
          <p>Header</p>
        </Collapse>
      </Item>
    </ButtonBase>
  );
};

function TabGroup() {
  return (
    <div>
      <h1>Topic: Redox Reaction</h1>
      <p>target: Form 5 Student</p>
      <ExpandTab />
      <ExpandTab />
      <ExpandTab />
      <ExpandTab />
    </div>
  );
}

function PhotoAndDescription() {
  return (
    <div>
      <img
        src={"/imgs/chisato.png"}
        style={{ borderRadius: 30, height: "65%", width: "80%" }}
      />
    </div>
  );
}

export function RelatedCourse() {
  return (
    <>
      <Box
        sx={{
          flexGrow: 1,
        }}
        style={{ justifyContent: "center", display: "flex" }}
      >
        <Grid
          container
          spacing={{ xs: 1, md: 1 }}
          columns={{ xs: 2, sm: 8, md: 12 }}
          style={{ width: "85%" }}
        >
          <Grid item xs={2} sm={8} md={4} key={"COURSES"}>
            <RelatedCourseComponent courseProp={course_1_data.courseProp} />
          </Grid>
          <Grid item xs={2} sm={8} md={4} key={"COURSES1"}>
            <RelatedCourseComponent courseProp={course_2_data.courseProp} />
          </Grid>
          <Grid item xs={2} sm={8} md={4} key={"COURSES2"}>
            <RelatedCourseComponent courseProp={course_3_data.courseProp} />
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
export function ResponsiveGrid() {
  return (
    <Box
      sx={{
        flexGrow: 1,
      }}
      style={{ justifyContent: "center", display: "flex" }}
    >
      <Grid
        container
        spacing={{ xs: 1, md: 1 }}
        columns={{ xs: 2, sm: 8, md: 12 }}
        style={{ width: "75%" }}
      >
        <Grid item xs={2} sm={8} md={6} key={"ASSSS1"}>
          <PhotoAndDescription />
        </Grid>
        <Grid item xs={2} sm={8} md={6} key={"ASDASDAS2"}>
          <TabGroup />
        </Grid>
      </Grid>
    </Box>
  );
}

export default function CourseDetail() {
  let { id } = useParams();
  return (
    <div style={{ justifyContent: "center" }}>
      <h1 style={{ textAlign: "center", margin: 30 }}>CY CHEMISTRY COURSE</h1>
      <div style={{ justifyContent: "center", display: "flex" }}>
        <ResponsiveGrid />
      </div>
      <div
        style={{ justifyContent: "center", display: "flex", marginTop: 100 }}
      >
        <Button variant="contained" style={{ width: "50%" }}>
          Click To Join!
        </Button>
      </div>

      <div style={{ marginTop: 200, marginBottom: 200 }}>
        <h1 style={{ textAlign: "center", padding: 0, width: "100%" }}>
          You may also like:
        </h1>
        <RelatedCourse />
      </div>
    </div>
  );
}
