import * as React from "react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { CardActionArea } from "@mui/material";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import { Nav, Navbar as NavbarBs } from "react-bootstrap";
import { NavLink } from "react-router-dom";
let items = [
  "/imgs/chisato.png",
  "/imgs/takina.png",
  "/imgs/kurumi.png",
  "/imgs/kurumi.png",
  "/imgs/kurumi.png",
];

interface CourseProp {
  url: string;
}

const Container = (courseProp: CourseProp) => {
  const [shown, setIsShown] = React.useState(false);
  return (
    <div
      style={{ height: "600px", width: "100%", margin: "16px" }}
      onMouseEnter={() => setIsShown(true)}
      onMouseLeave={() => setIsShown(false)}
    >
      <Nav.Link
        to={"/Course/CourseDetails/" + "5"}
        as={NavLink}
        style={{ height: "100%", width: "100%" }}
      >
        <Card
          style={{
            height: "600px",
            width: "100%",
            padding: 0,
          }}
        >
          <CardActionArea
            style={{ height: "600px", justifyContent: "flex-end" }}
            href="https://docs.google.com/forms/d/e/1FAIpQLSdLshb3xPMX316IzyAjFgTrUc34CmSftile44b25DgmhvKI-w/viewform"
          >
            <CardMedia
              component="img"
              height={shown ? "70%" : "60%"}
              image={courseProp.url}
              alt="green iguana"
              style={{ marginTop: 0 }}
            />
            <CardContent style={{ height: "600px" }} className="hover-zoom">
              <Typography gutterBottom variant="h5" component="div">
                Lizard
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Lizards are a widespread group of squamate reptiles, with over
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
      </Nav.Link>
    </div>
  );
};

function ReturnPage(grade: string) {
  switch (grade) {
    case "Form4":
      return 0;
    case "Form5":
      return 1;
    case "Form6":
      return 2;
    default:
      return 3;
  }
}

export default function Courses() {
  const [selectedPage, setSelectedPage] = React.useState("");
  return (
    <div>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Stack
          direction="row"
          divider={
            <Divider
              style={{ background: "black" }}
              orientation="vertical"
              flexItem
            />
          }
          spacing={2}
        >
          <Button
            variant={selectedPage == "Form4" ? "contained" : "outlined"}
            style={{ borderRadius: 20 }}
            onClick={() => {
              setSelectedPage("Form4");
            }}
          >
            F.4
          </Button>
          <Button
            variant={selectedPage == "Form5" ? "contained" : "outlined"}
            style={{ borderRadius: 20 }}
            onClick={() => {
              setSelectedPage("Form5");
            }}
          >
            F.5
          </Button>
          <Button
            variant={selectedPage == "Form6" ? "contained" : "outlined"}
            style={{ borderRadius: 20 }}
            onClick={() => {
              setSelectedPage("Form6");
            }}
          >
            F.6
          </Button>
        </Stack>
      </div>
      <Box
        sx={{
          flexGrow: 1,
        }}
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Grid
          container
          spacing={{ xs: 2, md: 3 }}
          columns={{ xs: 12, sm: 4, md: 12 }}
          style={{
            width: "70%",
          }}
        >
          {items.map((u, index) => (
            <Grid item xs={12} sm={4} md={3} key={index}>
              <Container url={u} />
            </Grid>
          ))}
        </Grid>
      </Box>
    </div>
  );
}
