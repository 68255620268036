import { gql } from "@apollo/client";

export const GET_USER = gql`
  query userCoursesVideos {
    authenticatedItem {
      ... on User {
        name
        grade
        expireDate
        courses(orderBy: { index: asc }) {
          name
          tutorial_Videos(orderBy: { part: asc }) {
            part
            url
            chapter
            expireDate
          }
        }
        studentWork {
          name
          school
          link
        }
      }
    }
  }
`;

export const UPDATE_USER_LOGIN_STATUS = gql`
  mutation ($email: String!, $lastLoginTime: String!, $accessToken: String!) {
    updateUser(
      where: { email: $email }
      data: {
        isLogin: true
        lastLoginTime: $lastLoginTime
        accessToken: $accessToken
      }
    ) {
      id
    }
  }
`;

export const CHECK_USER_MUTATION = gql`
  mutation CheckToken($email: String!, $sessionToken: String!) {
    CheckSessionToken(email: $email, sessionToken: $sessionToken) {
      status
    }
  }
`;
export const LOGOUT_USER_UPDATE = gql`
  mutation ($email: String!) {
    updateUser(
      where: { email: $email }
      data: { isLogin: false, lastLoginTime: "", accessToken: "" }
    ) {
      id
    }
  }
`;
