import React, { useEffect, useState } from "react";
import { useQuery, useMutation } from "@apollo/client";
import TextField from "@mui/material/TextField";
import {
  StudentWorkProp,
  VideoTable,
  VideoTableProps,
} from "../component/VideoTable";
import { VideoProp } from "../component/VideoTable";
import { Button, Paper } from "@mui/material";
import LoginIcon from "@mui/icons-material/Login";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { LOGIN_GQL, LOGOUT_GQL, CHECK_LOGIN_GQL } from "../graphql/Login";
import {
  CHECK_USER_MUTATION,
  GET_USER,
  LOGOUT_USER_UPDATE,
  UPDATE_USER_LOGIN_STATUS,
} from "../graphql/User";
import Banner from "../component/VideoBanner";
import { VimeoVideoPlayerComponent } from "../component/VimeoVideoPlayerComponent";
function refreshPage() {
  window.location.reload();
}
function getCurrentDateFormatted(): string {
  const currentDate = new Date();
  const formattedDate = currentDate
    .toISOString()
    .slice(0, 19)
    .replace("T", " ");

  return formattedDate;
}
export interface CourseProps {
  tutorial_Videos: VideoProp[];
  name: string;
}

export default function LoginPage() {
  const [coursesTable, setCoursesTable] = useState<CourseProps[]>([]);
  const [videoAttributes, setVideoAttributes] = useState<VideoProp[]>([]);
  const [studentWorks, setStudentWorks] = useState<StudentWorkProp[]>([]);
  const [finished, setFinished] = useState(false);
  const [closeTab, setCloseTab] = useState(false);
  const [debug, setDebug] = useState("false");
  const [emailToInput, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loginFeedBack, setLoginFeedBack] = useState("");
  const [sessionToken, setSessionToken] = useState("");
  const [url, setUrl] = useState("");
  useEffect(() => {
    const sessionTokenTemplate = sessionStorage
      .getItem("sessionTokenData")
      ?.toString();
    console.log(sessionTokenTemplate);
    const interval = setInterval(() => {
      const emailTemplate = sessionStorage.getItem("emailTemplate")?.toString();
      const sessionTokenTemplate = sessionStorage
        .getItem("sessionTokenData")
        ?.toString();
      console.log(sessionTokenTemplate);
      if (
        sessionStorage.getItem("sessionTokenData") &&
        sessionStorage.getItem("emailTemplate")
      ) {
        checkTokenFunction({
          variables: {
            email: emailTemplate,
            sessionToken: sessionTokenTemplate,
          },
          fetchPolicy: "no-cache",
        });
      }
    }, 10000);

    // Cleanup function to clear the interval when the component unmounts
    return () => {
      clearInterval(interval);
    };
  }, []);
  useEffect(() => {
    //logout when tab is closed
    if (sessionStorage.getItem("reloaded") != null) {
      console.log("page was reloaded");
    } else {
      // updateUserLogoutInfoFunction({
      //   variables: {
      //     email: sessionStorage.getItem("emailTemplate"),
      //   },
      //   fetchPolicy: "no-cache",
      // });
      handleLogout();
    }
    sessionStorage.setItem("reloaded", "Page Started");
  });

  const handleBeforeUnload = (event: BeforeUnloadEvent) => {
    event.preventDefault();
  };
  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };
  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
  };
  const handleLogout = async () => {
    doLogout({
      fetchPolicy: "no-cache",
    });
  };

  const playVideoCallBack = (link: string) => {
    setUrl(link);
  };

  // Query
  const [mutateFunction, { data, loading, error }] = useMutation(LOGIN_GQL, {
    onCompleted(data) {
      let type = data?.authenticateUserWithPassword?.__typename;
      if (type == "UserAuthenticationWithPasswordSuccess") {
        const sessionTokenGot =
          data?.authenticateUserWithPassword?.sessionToken;
        sessionStorage.setItem("emailTemplate", emailToInput.toString());
        sessionStorage.setItem("sessionTokenData", sessionTokenGot);
        console.log("token: " + sessionTokenGot);
        setSessionToken(sessionTokenGot);
        setFinished(true);
        setDebug("successs");

        updateUserLoginStatusFunction({
          variables: {
            email: emailToInput,
            lastLoginTime: getCurrentDateFormatted(),
            accessToken: sessionTokenGot,
          },
          fetchPolicy: "no-cache",
        });
      } else {
        setLoginFeedBack("Invalid Password or Email");
      }
      refreshPage();
    },
  });
  const [
    updateUserLoginStatusFunction,
    {
      data: updateUserLoginStatusFunctionData,
      loading: updateUserLoginStatusFunctionLoading,
      error: updateUserLoginStatusFunctionError,
    },
  ] = useMutation(UPDATE_USER_LOGIN_STATUS, {
    onCompleted(data) {},
  });
  const [
    updateUserLogoutInfoFunction,
    {
      data: updateUserLogoutInfoFunctionData,
      loading: uupdateUserLogoutInfoFunctionLoading,
      error: updateUserLogoutInfoFunctionError,
    },
  ] = useMutation(LOGOUT_USER_UPDATE, {
    onCompleted(data) {},
  });
  const [
    checkLoginValidMutationFunction,
    {
      data: checkLoginValidData,
      loading: checkLoginValidDataLoading,
      error: checkLoginValidDataError,
    },
  ] = useMutation(CHECK_LOGIN_GQL, {
    onCompleted(data) {
      let status = data?.CheckLoginValid?.status;
      mutateFunction({
        variables: {
          email: emailToInput,
          password: password,
        },
        fetchPolicy: "no-cache",
      });
      // if (status == "OK") {
      //   mutateFunction({
      //     variables: {
      //       email: emailToInput,
      //       password: password,
      //     },
      //     fetchPolicy: "no-cache",
      //   });
      // } else {
      //   setLoginFeedBack("You already have logged in from other device");
      // }
    },
  });
  const [
    checkTokenFunction,
    {
      data: checkTokenFunctionData,
      loading: checkTokenFunctionLoading,
      error: checkTokenFunctionError,
    },
  ] = useMutation(CHECK_USER_MUTATION, {
    onCompleted(data) {
      let status = data?.CheckSessionToken?.status;
      if (status == "OK") {
      } else {
        // updateUserLogoutInfoFunction({
        //   variables: {
        //     email: sessionStorage.getItem("emailTemplate"),
        //   },
        //   fetchPolicy: "no-cache",
        // });
        handleLogout();
      }
    },
  });

  const {
    loading: userLoading,
    error: userError,
    data: userData,
  } = useQuery(GET_USER);

  const [doLogout, { data: logoutData }] = useMutation(LOGOUT_GQL, {
    onCompleted() {
      sessionStorage.clear();
      setFinished(false);
      refreshPage();
    },
  });
  useEffect(() => {
    let type = data?.authenticateUserWithPassword?.__typename;
    if (type == "UserAuthenticationWithPasswordSuccess") {
      setFinished(true);
    }
  });
  // Effect
  useEffect(() => {
    // Update the document title using the browser API
    if (userData) {
      if (userData.authenticatedItem) {
        let courses = userData.authenticatedItem?.courses;
        if (courses.length > 0) {
          let newCoursesToBeSet: CourseProps[] = [];
          courses.map((course: CourseProps, courseIdx: number) => {
            newCoursesToBeSet.push(course);
          });

          setCoursesTable(courses);
        }
      }

      //   setVideoAttributes(videos);
      // }
    }

    if (userData?.authenticatedItem) {
      setFinished(true);
    }
    // setFinished(true);
  }, [userData, finished]);

  // States

  return (
    <>
      {finished && (
        <>
          <Banner content="CYCHEMISTRY - Online Video Course"></Banner>
          <Paper
            style={{
              display: "flex",
              justifyContent: "center",
              position: "relative",
            }}
          >
            <div>
              <AccountCircleIcon
                style={{
                  width: "100%",
                  height: 100,
                }}
              />
              <h1 style={{ textAlign: "center" }}>
                {userData?.authenticatedItem?.name}
              </h1>
              <p style={{ textAlign: "center" }}>
                {"Number of courses: " +
                  userData?.authenticatedItem?.courses.length}
              </p>
              <div
                style={{
                  position: "absolute",
                  top: 10,
                  right: 10,
                  padding: 10,
                }}
              >
                {"Expiry Date: " + userData?.authenticatedItem?.expireDate}
              </div>
            </div>
          </Paper>

          <div
            style={{
              width: "100%",
              aspectRatio: "16/5",
              backgroundColor: "lightgray",
              position: "relative",
              display: "flex",
              justifyContent: "center",
              backgroundImage: `url(${require("../imgs/Artboard1.png")})`,
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
            }}
          >
            <VimeoVideoPlayerComponent url={url} />
          </div>
          <div
            style={{
              marginTop: 150,
              position: "relative",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              backgroundImage: `url(${require("../imgs/VideoTableBackGround.png")})`,
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
            }}
          >
            {coursesTable.map((course: CourseProps, courseIdx: number) => (
              <VideoTable
                key={"courseVideoTable" + courseIdx.toString()}
                courseTitle={course.name}
                videoInfos={course.tutorial_Videos}
                studentWorks={studentWorks}
                playVideo={playVideoCallBack}
              />
            ))}
          </div>

          <div
            style={{
              position: "relative",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Button
              style={{
                width: "25%",
                height: "50px",
                backgroundColor: "black",
                margin: 100,
              }}
              variant="contained"
              onClick={() => {
                console.log(emailToInput);
                updateUserLogoutInfoFunction({
                  variables: {
                    email: sessionStorage.getItem("emailTemplate"),
                  },
                  fetchPolicy: "no-cache",
                });
                handleLogout();
              }}
            >
              Logout
            </Button>
          </div>
          <Banner content="NO ONE CAN MAKE YOU FEEL INFERIOR WITHOUT YOUR CONSENT"></Banner>
        </>
      )}
      {!finished && (
        <div
          style={{
            backgroundColor: "lightgray",
            height: "100vh",
            width: "100vw",
            position: "absolute",
            top: 0,
            left: 0,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundImage: `url(${require("../imgs/loginHomePage.png")})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
          }}
        >
          <div
            style={{
              backgroundColor: "white",
              display: "flex",
              justifyContent: "center",
              height: "500px",
              width: "300px",
              borderRadius: 20,
              marginTop: 220,
              boxShadow: "10px 10px 5px grey",
              border: "1px solid black",
            }}
          >
            <div style={{ width: "85%" }}>
              <LoginIcon
                style={{
                  width: "80%",
                  height: 100,
                }}
              />
              <h1
                style={{
                  textAlign: "center",
                }}
              >
                Log in
              </h1>
              <br></br>

              <TextField
                id="outlined-name"
                label="Name"
                value={emailToInput}
                onChange={handleNameChange}
                style={{
                  width: "100%",
                }}
              />
              <br></br>
              <br></br>
              <TextField
                id="outlined-password-input"
                label="Password"
                type="password"
                autoComplete="current-password"
                helperText={loginFeedBack}
                value={password}
                onChange={handlePasswordChange}
                style={{ width: "100%" }}
              />
              <div
                style={{
                  width: "100%",
                  height: "20%",
                  justifyContent: "center",
                  alignItems: "center",
                  display: "flex",
                }}
              >
                <div>
                  <Button
                    style={{ width: "50%", backgroundColor: "black" }}
                    variant="contained"
                    onClick={() => {
                      checkLoginValidMutationFunction({
                        variables: {
                          email: emailToInput,
                        },
                        fetchPolicy: "no-cache",
                      });
                    }}
                  >
                    LOGIN
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
